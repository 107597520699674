// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-paginated-posts-js": () => import("./../../../src/components/PaginatedPosts.js" /* webpackChunkName: "component---src-components-paginated-posts-js" */),
  "component---src-components-templates-gallery-js": () => import("./../../../src/components/templates/Gallery.js" /* webpackChunkName: "component---src-components-templates-gallery-js" */),
  "component---src-components-templates-image-js": () => import("./../../../src/components/templates/Image.js" /* webpackChunkName: "component---src-components-templates-image-js" */),
  "component---src-components-templates-post-js": () => import("./../../../src/components/templates/Post.js" /* webpackChunkName: "component---src-components-templates-post-js" */),
  "component---src-components-templates-you-tube-js": () => import("./../../../src/components/templates/YouTube.js" /* webpackChunkName: "component---src-components-templates-you-tube-js" */),
  "component---src-pages-open-graph-helper-js": () => import("./../../../src/pages/OpenGraphHelper.js" /* webpackChunkName: "component---src-pages-open-graph-helper-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/Profile.js" /* webpackChunkName: "component---src-pages-profile-js" */)
}

